<template>
  <div :class="['point-area', ripple && 'ripple-point']" :style="`--bg-color:${color}`">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    // 颜色
    color: {
      type: String,
      default: '#85CF26'
    },
    // 是否有波纹效果
    ripple: {
      type: Boolean,
      default: false
    }
  },
  name: 'Point'
}
</script>

<style lang="scss" scoped>
.point-area {
  position: relative;
  display: inline-block;
  padding-left: 1em;
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--bg-color, #85cf26);
  }
}
.ripple-point {
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: 3px;
    width: 6px;
    height: 6px;
    background: var(--bg-color, #85cf26);
    border-radius: 50%;
    animation: ripple 1.2s infinite ease-in-out;
  }
  @keyframes ripple {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.4);
      opacity: 0;
    }
  }
}
</style>
