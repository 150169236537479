<template>
  <div class="cabinet-list-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="柜机编码：">
        <el-input placeholder="请输入柜机编码" v-model="searchForm.lockerCode"></el-input>
      </el-form-item>
      <el-form-item label="企业ID：">
        <el-input placeholder="请输入企业ID" v-model="searchForm.customerCode"></el-input>
      </el-form-item>
      <el-form-item label="企业名称：">
        <el-input placeholder="请输入企业名称" v-model="searchForm.customerName"></el-input>
      </el-form-item>
      <el-form-item label="创建人：">
        <el-input placeholder="请输入创建人" v-model="searchForm.createUser"></el-input>
      </el-form-item>
      <el-form-item label="产品类型：">
        <Select clearable v-model="searchForm.productCode" :list="productNameList"> </Select>
      </el-form-item>
      <el-form-item label="室内/外：">
        <Select clearable v-model="searchForm.lockerType" :list="lockerTypeList"> </Select>
      </el-form-item>
      <el-form-item label="柜机状态：">
        <Select clearable v-model="searchForm.lockerStatus" :list="lockerList"> </Select>
      </el-form-item>
      <el-form-item label="柜机规格：">
        <Select clearable v-model="searchForm.viceType" :list="viceTypeList"> </Select>
      </el-form-item>
      <el-form-item label="烧录编码：">
        <Select clearable v-model="searchForm.burnWay" :list="burnWayTypeList"> </Select>
      </el-form-item>
      <el-form-item label="信号状态：">
        <Select clearable v-model="searchForm.signalStatus" :list="signalList"> </Select>
      </el-form-item>
      <el-form-item label="激活时间：">
        <el-date-picker
          v-model="operationTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="success" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row>
      <el-button type="success" size="small" @click="newlyAdded">新增</el-button>
      <el-button size="small" @click="handleDelete">删除</el-button>
    </el-row>

    <Table
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @selection-change="handleSelectionChange"
      @pagination="updatePage"
    >
      <el-table-column slot="selection" type="selection" width="50" fixed="left"> </el-table-column>
      <el-table-column slot="operate" label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button style="color: #77b922" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <el-link
        slot="lockerCode"
        type="primary"
        slot-scope="{ originScope }"
        @click="handleClickLockerCode(originScope.row)"
      >
        {{ originScope.row.lockerCode }}
      </el-link>
      <el-link
        slot="viceTypeName"
        type="primary"
        slot-scope="{ originScope }"
        @click="handleClickViceType(originScope.row)"
      >
        {{ originScope.row.viceTypeName }}
      </el-link>
      <template #lockerType="{ originScope }">
        {{ originScope.row.lockerTypeName }}
      </template>
      <template #lockerStatus="{ originScope }">
        <Point slot="pickupStatusText" :color="originScope.row.lockerStatus | lockerStatusColor">{{
          originScope.row.lockerStatus | lockerStatusStr
        }}</Point>
      </template>
      <template #signalStatus="{ originScope }">
        <Point slot="pickupStatusText" :color="originScope.row.signalStatus | signalStatusColor">{{
          originScope.row.signalStatusName
        }}</Point>
      </template>
    </Table>

    <!-- 柜机规格 -->
    <PublicDiaTc title="" :width="'920px'" :locker-code="lockerCode" :visible.sync="visible"> </PublicDiaTc>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
// import Dialog from '@/components/Dialog'
import Select from '@/components/Select'
import Point from '@/components/Point'
import PublicDiaTc from '../../components/public-dia-tc'
import { getpageLockerList, removeLocker } from '@/api/modules/cabinet'
import { getVendorList } from '@/api/modules/device'
// import { strViType } from '../../components/selectStr'

export default {
  name: 'cabinetList',
  components: {
    Table,
    // Dialog,
    Select,
    Point,
    PublicDiaTc
  },
  data() {
    return {
      searchForm: {
        lockerCode: '',
        customerCode: '',
        companyName: '',
        customerName: '',
        productCode: '',
        lockerType: '',
        lockerStatus: '',
        viceType: '',
        burnWay: '',
        // country: '',
        signalStatus: '',
        activeStartTm: '',
        activeEndTm: ''
      },
      columns: [
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '产品类型', prop: 'productName' },
        { label: '烧录编码', prop: 'burnWayName' },
        { label: '柜机规格', prop: 'viceTypeName' },
        { label: '企业ID', prop: 'customerCode' },
        { label: '企业名称', prop: 'customerName' },
        { label: '室内/外', prop: 'lockerType' },
        { label: '供应商', prop: 'manufactureName' },
        { label: '柜机状态', prop: 'lockerStatus' },
        { label: '信号状态', prop: 'signalStatus' },
        { label: '最近心跳时间', prop: 'lastHeartbeatTime', width: 160 },
        { label: '激活时间', prop: 'activeTime', width: 160 },
        { label: '最后一次激活时间', prop: 'currentActiveSysTm', width: 160 },
        // { label: '国家', prop: 'country' },
        // { label: '城市', prop: 'city' },
        { label: '创建主体', prop: 'createdPlatformName' },
        { label: '创建人', prop: 'createUser' },
        { label: '创建时间', prop: 'createTime', width: 160 }
      ],
      operationTime: [],
      tableData: [],
      selectedRows: [],
      lockerList: [
        { value: 1, label: '未使用' },
        { value: 2, label: '使用中' },
        { value: 3, label: '维护中' },
        { value: 4, label: '弃用' }
      ],
      lockerTypeList: [
        { value: 1, label: '室内机' },
        { value: 2, label: '室外机' }
      ],
      signalList: [
        { value: 0, label: '离线' },
        { value: 1, label: '在线' }
      ],
      // countryTypeList: [],
      viceTypeList: [],
      burnWayTypeList: [
        { value: 1, label: '必须烧录' },
        { value: 2, label: '都可以' }
      ],
      productNameList: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      lockerCode: '',
      visible: false,
      tableLoading: false,
      manufactureNameList: []
    }
  },
  filters: {
    lockerStatusColor(value) {
      const color = ['#1472FF', '#85CF26', '#F1924E', '#A2A3A5']
      return color[value]
    },
    signalStatusColor(value) {
      if (value === 1) {
        return '#85CF26'
      }
      if (value === 0) {
        return '#DF4545'
      }
      return '#ffffff'
    },
    lockerStatusStr(value) {
      const str = ['', '未使用', '使用中', '维护中', '弃用']
      return str[value]
    }
  },
  created() {
    this.onSearch(true)
    !this.productNameList.length && this.getVendor('productNameList', 'productType')
    // !this.countryTypeList.length && this.getVendor('countryTypeList', 'country')
    !this.manufactureNameList.length && this.getVendor('manufactureNameList', 'supplier')
    !this.viceTypeList.length && this.getVendor('viceTypeList', 'LockerType')
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getpageLockerList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    // 获取产品，国家，类型列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        // console.log('getVendor-res: ', res)
        const { records } = res.data || []
        this[fn] = records
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        lockerCode: '',
        customerCode: '',
        companyName: '',
        customerName: '',
        productCode: '',
        lockerType: '',
        lockerStatus: '',
        viceType: '',
        burnWay: '',
        // country: '',
        signalStatus: '',
        activeStartTm: '',
        activeEndTm: ''
      }
      this.operationTime = []
      this.onSearch()
    },
    newlyAdded() {
      this.$router.push({ name: 'cabinetListCreates' })
    },
    // 跳转柜机详情
    handleClickLockerCode(row) {
      this.$router.push({ name: 'cabinetListDetails', query: { id: row.lockerCode || '' } })
    },
    // 显示柜机规格
    handleClickViceType(row) {
      this.visible = true
      this.lockerCode = row.lockerCode
      console.log('handleClickViceType: ', row, this.lockerCode)
    },
    handleDelete() {
      const arr = this.selectedRows
      const can = arr.reduce((t, v) => (v.lockerStatus === 1 ? [...t, v.lockerCode] : t), [])
      if (!arr.length) {
        this.$message.warning('请先选中内容在进行删除')
        return
      }
      if (!can.length) {
        this.$message.warning('所选内容存在已激活设备，不可删除')
      } else {
        const lockerCodeList = can
        const confirmTips = '确认对选中的内容进行删除吗？'
        this.$confirm(confirmTips)
          .then(() => {
            removeLocker({ lockerCodeList }).then((res) => {
              if (res.code === '000000000') {
                this.$message.success(res.msg)
                this.onSearch(false)
              } else {
                this.$message.warning(res.msg)
              }
            })
          })
          .catch(() => this.$message.warning('已取消删除'))
      }
    },
    handleEdit(row) {
      console.log('handleEdit: ', row)
      this.$router.push({ name: 'cabinetListCreates', query: { id: row.lockerCode || '' } })
    },
    // 监听时间变化
    monitorTime(val) {
      const timedata = []
      val &&
        val.forEach((item) => {
          timedata.push(new Date(item))
        })
      const [start, end] = timedata
      this.searchForm.activeStartTm = start
      this.searchForm.activeEndTm = end
      this.onSearch()
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 处理表格选中
    handleSelectionChange(selection) {
      console.log('handleSelectionChange!', selection)
      this.selectedRows = selection
    }
  }
}
</script>

<style lang="scss" scoped>
.cabinet-list-wrap {
  padding: 24px;
}
.el-link {
  font-weight: 400;
}
</style>
